import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
//import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
//import Tooltip from 'react-bootstrap/Tooltip';
import face from '../images/facePicture.jpg';

const NavigationBar = () => {

    {/*const emailExpain = (props) => (
        <Tooltip id = 'button-tooltip' {...props}>
            test
        </Tooltip>
    );*/}

    return(
        <Container>
            <Navbar expand='lg' className='bg-info bg-opacity-50 bg-gradient border border-dark'>
                <Navbar.Brand href="/">
                    Dominic Babusci
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='basic-navbar-nav'/>
                <Navbar.Collapse id='basic-navbar-nav'>                    
                    <Nav className='me-auto'>
                    <Nav.Link href='education'>Education</Nav.Link>
                    {/*<Nav.Link href='resume'>Resume</Nav.Link>*/}
                        <NavDropdown title='Portfolio' id='basic-nav-dropdown'>
                            <NavDropdown.Item href='ep'>Experience</NavDropdown.Item>
                            <NavDropdown.Item href='personalProjects'>Personal Projects</NavDropdown.Item>
                            <NavDropdown.Item href='schoolProjects'>School Projects</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title='Social Links' id='basic-nav-dropdown'>
                            <NavDropdown.Item href='https://www.linkedin.com/in/dbabusci/' target='_blank'>LinkedIn</NavDropdown.Item>
                            <NavDropdown.Item href='https://www.github.com/dbabusci' target='_blank'>Github</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title='Contact Me' id='basic-nav-dropdown' className='justify-content-end'>
                            <NavDropdown.Item href='mailto:dbabusci@kent.edu'>dbabusci@kent.edu</NavDropdown.Item>
                            <NavDropdown.Item href='mailto:dominicbabusci@gmail.com'>dominicbabusci@gmail.com</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Container>
    );    
}

export default NavigationBar;