import PDFHolder from '../components/pdfHolder';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Resume() {
    return(
        <Container className='align-center fluid'>
            <Row className='py-4'>
                <Col className='d-flex justify-content-center'>
                    <h1>Resume</h1>
                </Col>
            </Row>
            <Row>
                <Col className='w-75 d-flex justify-content-center border border-dark'>
                    <PDFHolder/>
                </Col>
            </Row>
        </Container>  
    );
}

export default Resume;