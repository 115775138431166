import React from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import pdfURL from './Dominic_Babusci_Resume.pdf';

//pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js',import.meta.url,).toString();

const PDFHolder = () => {
    return (
        <Document
            file={pdfURL}
            noData={console.log("No data :(")}
            onLoadError={console.error}
            >
            <Page pageNumber = {1}/>
        </Document>
    );
};
export default PDFHolder;