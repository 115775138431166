import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import '../App.js';
import face from '../images/facePicture.jpg';

function Home() {
    return(
        <Container className='align-center fluid'>
            <Row className='pb-4 pt-4'>
                <Col className ='d-flex justify-content-center'>
                    <h1>
                        Dominic Babusci
                    </h1>
                </Col>
            </Row>
            <Row className='pb-5'>
                <Col> {/*className ='d-flex justify-content-center'*/}
                    <Image className='float-start p-5' src={face} fluid />
                    <p className='fs-4 text-center p-5'>
                    I am Dominic Babusci, I am currently an undergraduate at Kent State University. 
                    I created this website as a way to show all of my accomplishments and skills. 
                    Hopefully this will give you a greater context to the skills I have listed in my resume. 
                    Information about my education, personal projects, school projects, and previous experience 
                    can be found at the top. Coding related social media (for lack of a better word) 
                    can be found in the social dropdown. This includes links to my github as well as linkedin. 
                    If you have any questions for me, hit “Contact me” to send an email to me.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default Home;