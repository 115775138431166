import '../App.js';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function PersonalProjects() {
    return(
        <Container className='w-75 py-3'>
            <Row className='py-3'>
                <Col className='d-flex justify-content-center'>
                    <h1>Personal Projects</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Accordion defaultActiveKey='0' flush>
                        <Accordion.Item eventKey='1'>
                            <Accordion.Header>Dominic Babusci Website</Accordion.Header>
                            <Accordion.Body className='bg-info bg-opacity-50 bg-gradient'>
                                This website was made using React with javascript, HTML, and Bootstrap. The main goal of this website
                                was to show my accomplishments as detailed on my resume in greater detail. As well as
                                provide a hub to all of my work related links like github, and linkedin. The website is being
                                consistantly updated in order to reflect the changes in my skills and accomplishments.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='2'>
                            <Accordion.Header>Colin's Birthday Countdown</Accordion.Header>
                            <Accordion.Body className='bg-info bg-opacity-50 bg-gradient'>
                                I created a webpage to help my office countdown the days until Colin's birthday. The
                                webpage was created using the React framework with javascript, HTML, and Bootstrap. The
                                web page is being hosted on Vercel. As of the time editing this, the webpage requires some
                                minor quality of life changes, but the core functionality is there.
                                <br/>
                                <a href='https://colin-countdown.vercel.app/'>Colin's Countdown</a>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
}

export default PersonalProjects;