import '../App.js';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function SchoolProjects() {
    return(
        <Container className=' w-75 py-3'>
            <Row className='py-3'>
                <Col className='d-flex justify-content-center'>
                    <h1>School Projects</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Accordion defaultActiveKey='0' flush>
                        <Accordion.Item eventKey='1'>
                            <Accordion.Header>HCIGiovanniFanclub</Accordion.Header>
                            <Accordion.Body className='bg-info bg-opacity-50 bg-gradient'>
                                The goal of this project was to build a website that showed the 8 golden rules of interface design.
                                These rules are, Strive for consistancy, enable frequent users to use shortcuts, offer informative 
                                feedback, design dialogue to yield closure, offer simple error handling, permit easy reversal of 
                                actions, support internal locus of control, and reduce short term memory load. We used these rules
                                to create our website, furthermore we had pages that showed good and bad example of each rule. The 
                                website was build using html5 with bootstrap for style.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='2'>
                            <Accordion.Header>Greg^3</Accordion.Header>
                            <Accordion.Body className='bg-info bg-opacity-50 bg-gradient'>
                                This was a group project made with p5js. In the game you scroll through an interactable cube with 
                                puzzles on each side. You then solve the puzzles while a time ticks down. The main goal of the 
                                project was to help teach us howto properly develop software. Our group used agile development with 
                                weekly sprints to build our project. Overall this project helped reinforce my love for software 
                                development.
                                <br/>
                                <a href='https://deltagroupswe.github.io/DeltaMain/public/pages/home/index.html'>Greg^3</a>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='3'>
                            <Accordion.Header>Death and Taxes Bank</Accordion.Header>
                            <Accordion.Body className='bg-info bg-opacity-50 bg-gradient'>
                                This was a group project that used HTML and php to connect a frontend to a mock bank database.
                                This allowed users to perform various operation commonly seen at a bank. The database was made 
                                with MYSQL.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
}

export default SchoolProjects;