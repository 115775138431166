import {BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import Container from "react-bootstrap/Container";
import './App.css';
import Education from './pages/education';
import EP from './pages/ep';
import Home from './pages/home';
import PersonalProjects from './pages/personalProjects';
import SchoolProjects from './pages/schoolProjects';
import NavigationBar from "./components/navBar";
import Resume from "./pages/resume";

function App() {
  return (
    <Container className="min-vw-100 min-vh-100 w-100 h-100 bg-light bg-gradient">
      <BrowserRouter>
      <NavigationBar/>
      <Routes>
        <Route path = "resume" element = {<Resume/>}/>
        <Route path = "/" element = {<Home/>}/>
        <Route path = "education" element = {<Education/>}/>
        <Route path = "ep" element = {<EP/>}/>
        <Route path = "personalProjects" element = {<PersonalProjects/>}/>
        <Route path = "schoolProjects" element = {<SchoolProjects/>}/>
      </Routes>
    </BrowserRouter>
    </Container>
  );
}

export default App;
