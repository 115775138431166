import '../App.js';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row'

function EP() {
    return(
        <Container className='w-75 py-3'>
            <Row className='py-3'>
                <Col className='d-flex justify-content-center'>
                    <h1>
                        Experience
                    </h1>
                </Col>
            </Row>
            <Row className='py-2'>
                <Col>
                    <Accordion defaultActiveKey='0' flush>
                        <Accordion.Item eventKey='1'>
                            <Accordion.Header>Student Technician</Accordion.Header>
                            <Accordion.Body className='bg-info bg-opacity-50 bg-gradient'>
                                From September 2022 through the current day I have worked in the Kent State University
                                IT department. In this position I, help Kent State employees troubleshoot issues, set up
                                new devices, and set up new working enviornments.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='2'>
                            <Accordion.Header>Student Undergraduate Research Experience</Accordion.Header>
                            <Accordion.Body className='bg-info bg-opacity-50 bg-gradient'>
                                For the summer of 2023 I took part in the Student Undergraduate Research Experience.
                                During the program I used React with Tauri to develop a desktop application used to 
                                access a database that will hold data about GERD symptoms. Our goal is to streamline 
                                the GERD treatment process by allowing healthcare professionals to directly access up
                                to date data.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>    
        </Container>
    );
}

export default EP;