import '../App.js';
import Accordion  from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function Education() {
    return(
        <Container className='w-75 py-3'>
            <Row className='py-3'>
                <Col className='d-flex justify-content-center'>
                    <h1>
                        Education
                    </h1>
                </Col>
            </Row>
            <Row className='py-2'>
                <Col>
                    <Accordion defaultActiveKey='0' flush>
                        <Accordion.Item eventKey='1'>
                            <Accordion.Header>Kent State University</Accordion.Header>
                            <Accordion.Body className='bg-info bg-opacity-50 bg-gradient'>
                                I am currently a student at Kent State University studying Computer Science
                                By the time I graduate I also plan to have a math minor. I have a GPA of 3.412
                                with a 3.326 major GPA as of 2023 Fall. 
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='2'>
                            <Accordion.Header>CCNA</Accordion.Header>
                            <Accordion.Body className='bg-info bg-opacity-50 bg-gradient'>
                                In highschool, I was able to earn my CCNA through my school's Cisco pathwway.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>    
        </Container>
    );
}

export default Education;